/*eslint-disable*/
import React from "react";
import DeleteIcon from "@material-ui/icons/Delete";
import IconButton from "@material-ui/core/IconButton";
// react components for routing our app without refresh
import { Link, useHistory } from "react-router-dom";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Tooltip from "@material-ui/core/Tooltip";

// @material-ui/icons
import { Apps, Ballot, Phone, Home, Business, HorizontalSplit } from "@material-ui/icons";

// core components
import CustomDropdown from "components/CustomDropdown/CustomDropdown.js";
import Button from "components/CustomButtons/Button.js";

import styles from "assets/jss/material-kit-react/components/headerLinksStyle.js";

import logopse from "assets/img/logos/pse-forma.webp"

const useStyles = makeStyles(styles);

export default function HeaderLinks(props) {
  const classes = useStyles();
  const history = useHistory();

  return (
    <List className={classes.list}>
      <ListItem className={classes.listItem}>
        <Button
          color={"transparent"}
          target={"_blank"}
          className={classes.navLink}
          onClick={() => {
            history.push("/");
          }}
        >
          <Home className={classes.icons}/> Inicio
        </Button>
      </ListItem>
      <ListItem className={classes.listItem}>
        <CustomDropdown
          noLiPadding
          hoverColor={"warning"}
          buttonText={"Nosotros"}
          buttonProps={{
            className: classes.navLink,
            color: "transparent"
          }}
          buttonIcon={Business}
          dropdownList={[
            <Link to="/nosotros" className={classes.dropdownLink}>
              Acerca de
            </Link>,
            <Link to={"/tratamiento-de-datos"} className={classes.dropdownLink}>
              Política de tratamiento de datos
            </Link>,
          ]}

        />
      </ListItem>
      <ListItem className={classes.listItem}>
        <Button
          color="transparent"
          target="_blank"
          className={classes.navLink}
          onClick={() => {
            history.push("/marcas");
          }}
        >
          <Apps className={classes.icons} /> Marcas
        </Button>
      </ListItem>
      {/*<ListItem className={classes.listItem}>
        <Button
          href="https://www.creative-tim.com/product/material-kit-react?ref=mkr-navbar"
          color="transparent"
          target="_blank"
          className={classes.navLink}
        >
          <CloudDownload className={classes.icons} /> Download
        </Button>
      </ListItem>*/}
      <ListItem className={classes.listItem}>
        <CustomDropdown
          noLiPadding
          hoverColor={"warning"}
          buttonText={"Productos"}
          buttonProps={{
            className: classes.navLink,
            color: "transparent"
          }}
          buttonIcon={HorizontalSplit}
          dropdownList={[
            <Link to="/fichas-seguridad" className={classes.dropdownLink}>
              Fichas de Seguridad y Técnicas
            </Link>,
          ]}

        />
      </ListItem>
      <ListItem className={classes.listItem}>
        <Tooltip
          id="phone-call"
          title="Comunícate con nosotros"
          placement={window.innerWidth > 959 ? "top" : "left"}
          classes={{ tooltip: classes.tooltip }}
        >
          <Button
            href="tel:+573202025864"
            target="_blank"
            color="transparent"
            className={classes.navLink}
          >
            <Phone className={classes.icons}/> Llámanos
          </Button>
        </Tooltip>
      </ListItem>
      <ListItem className={classes.listItem}>
        <Tooltip
          id="pagoss"
          title="Pagos en línea"
          placement={window.innerWidth > 959 ? "top" : "left"}
          classes={{ tooltip: classes.tooltip }}
        >
          <Button
            href="https://www.mipagoamigo.com/MPA_WebSite/ServicePayments/StartPayment?id=9869&searchedCategoryId=&searchedAgreementName=DEPOSITO%20JORDAN"
            target="_blank"
            color="transparent"
            className={classes.navLink}
          >
            
            Pagos en línea
          </Button>
        </Tooltip>
      </ListItem>
    </List>
  );
}
