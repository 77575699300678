import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons
import { LocationOn, Phone, Email } from "@material-ui/icons";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import InfoArea from "components/InfoArea/InfoArea.js";

import styles from "assets/jss/material-kit-react/views/landingPageSections/productStyle.js";

import Button from "../../../components/CustomButtons/Button";
import { isMobile } from "react-device-detect"

const useStyles = makeStyles(styles);

const altStyles = {
  infoArea: {
    minHeight: "300px",
    margin: "0 auto",
    padding: "0px"
  },
}
const altuseStyle = makeStyles(altStyles);
export default function ProductSection() {
  const classes = useStyles();
  const altclasses = altuseStyle();
  function getGeo() {
    if(isMobile){
      return "geo:4.4969644458096205,-74.10301266810444"
    }else{
      return "https://www.google.com/maps/dir//Dep%C3%B3sito+y+Ferreter%C3%ADa+El+Jord%C3%A1n/data=!4m8!4m7!1m0!1m5!1m1!1s0x8e3fa3c7c9176e29:0x1d354f1ce37fbe85!2m2!1d-74.103014!2d4.496951999999999"
    }
  }
  return (
    <div className={classes.section}>
      <GridContainer justify="center">
        <GridItem xs={12} sm={12} md={8}>
          <h2 className={classes.title}>Sobre Nosotros</h2>
          <h5 className={classes.description}>
            Ofrecemos gran variedad de materiales para la construcción de alta calidad
            para garantizar el buen desarrollo de su obra. Aliado de su negocio con
            la distribución de cementos cemex y otras marcas. Contamos con servicio de entrega
            en el área urbana de la localidad de Usme.
          </h5>
        </GridItem>
      </GridContainer>
      <br/>
      <GridContainer justify={"center"}>
        <GridItem xs={10} sm={10} md={12}>
          <div className={altclasses.infoArea}>
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3977.5356892425966!2d-74.10520268573643!3d4.4969519967324345!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8e3fa3c7c9176e29%3A0x1d354f1ce37fbe85!2sDep%C3%B3sito%20y%20Ferreter%C3%ADa%20El%20Jord%C3%A1n!5e0!3m2!1ses-419!2sco!4v1619538576722!5m2!1ses-419!2sco"
              width="100%"
              height="450"
              allowFullScreen=""
              loading="lazy"
            />
          </div>
        </GridItem>
      </GridContainer>
      <br/>
      <br/>
      <div>
        <GridContainer>
          <GridItem xs={12} sm={12} md={4}>
            <InfoArea
              title="Ubicación"
              description="Calle 91 Sur 6A 12 Este, Barrio Alfonso López, Bogotá D.C."
              icon={LocationOn}
              iconColor="warning"
              vertical
            />
            <Button
              color={"danger"}
              target={"_blank"}
              href={getGeo()}
            >
              ¿Como Llegar?
            </Button>
          </GridItem>
          <GridItem xs={12} sm={12} md={4}>
            <InfoArea
              title="Teléfonos"
              description={
                "601 763 0430\n - 601 763 0431\n - 320 2025864"
              }
              icon={Phone}
              iconColor="warning"
              vertical
            />
            <br/>
            <Button
              color={"danger"}
              target={"_blank"}
              href={
                "tel://3202025864"
              }
            >
              Llamar Ahora
            </Button>
          </GridItem>
          <GridItem xs={12} sm={12} md={4}>
            <InfoArea
              title="Correo Electrónico"
              description={
                "eljordanco@gmail.com"
              }
              icon={Email}
              iconColor="warning"
              vertical
            />
            <br/>
            <Button
              color={"danger"}
              target={"_blank"}
              href={
                "mailto:eljordanco@gmail.com"
              }
            >
              Enviar Correo
            </Button>
          </GridItem>
        </GridContainer>
      </div>
    </div>
  );
}
