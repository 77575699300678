import React from "react";
import { Viewer, Worker } from "@react-pdf-viewer/core";
import doc from "assets/docs/Tratamiento_de_Datos.pdf";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import { container, title } from "../../../assets/jss/material-kit-react";
import GridContainer from "../../../components/Grid/GridContainer";
import GridItem from "../../../components/Grid/GridItem";
import { Helmet } from "react-helmet";

const styles = {
  container: {
    zIndex: "12",
    color: "#FFFFFF",
    ...container,
  },
  main: {
    background: "#FFFFFF",
    position: "relative",
    zIndex: "3",
  },
  boxRaised: {
    margin: "50px 30px 0px",
    borderRadius: "6px",
    boxShadow:
      "0 16px 24px 2px rgba(0, 0, 0, 0.14), 0 6px 30px 5px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2)",
  },
  section: {
    padding: "70px 0",
    textAlign: "center",
  },
  title: {
    ...title,
    marginBottom: "1rem",
    marginTop: "30px",
    minHeight: "32px",
    textDecoration: "none",
  },
  description: {
    color: "#999",
  },
  justifyCenter: {
    justifyContent: "center !important",
  },
  pdfView: {
    maxHeight: "400px",
    maxWidth: "80%",
  },
};

// Sections for this page
const dashboardRoutes = [];
const useStyles = makeStyles(styles);

export default function DataPage(props) {
  const classes = useStyles();
  const { ...rest } = props;
  return (
    <div>
      <Helmet>
        <title>Política de Tratamiento de Datos | El Jordán</title>
      </Helmet>
      <Header
        color="dark"
        routes={dashboardRoutes}
        rightLinks={<HeaderLinks />}
        fixed
        {...rest}
      />
      <div className={classNames(classes.main, classes.boxRaised)}>
        <div className={classes.container}>
          <div className={classes.section}>
            <h2 className={classes.title}>Política de Tratamiento de Datos</h2>
            <GridContainer className={classes.justifyCenter}>
              <GridItem xs={12} sm={12} md={8}>
                <h5 className={classes.description}>
                  Esta Política de Protección de datos se aplicará a todas las
                  elementos que contengan datos personales que sean objeto de
                  tratamiento por parte de Depósito y Ferretería El Jordán
                  responsable del manejo de estos.
                </h5>
              </GridItem>
              <GridItem
                xs={9}
                sm={9}
                md={8}
                style={{
                  border: "1px solid rgba(0, 0, 0, 0.3)",
                  height: "750px",
                }}
              >
                <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.5.207/build/pdf.worker.min.js">
                  <Viewer fileUrl={doc} />
                </Worker>
              </GridItem>
            </GridContainer>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
