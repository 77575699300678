import React, { useCallback } from "react";

// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { Snackbar } from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";

// core components
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import { container, title } from "assets/jss/material-kit-react";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import CustomInput from "components/CustomInput/CustomInput";
import Button from "components/CustomButtons/Button";

const styles = {
  container: {
    zIndex: "12",
    color: "#FFFFFF",
    ...container,
  },
  main: {
    background: "#FFFFFF",
    position: "relative",
    zIndex: "3",
  },
  boxRaised: {
    margin: "50px 30px 0px",
    borderRadius: "6px",
    boxShadow:
      "0 16px 24px 2px rgba(0, 0, 0, 0.14), 0 6px 30px 5px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2)",
  },
  section: {
    padding: "70px 0",
    textAlign: "center",
  },
  title: {
    ...title,
    marginBottom: "1rem",
    marginTop: "30px",
    minHeight: "32px",
    textDecoration: "none",
  },
  description: {
    color: "#999",
  },
};

// Sections for this page
const dashboardRoutes = [];
const useStyles = makeStyles(styles);

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default function CotizacionPage(props) {
  const classes = useStyles();
  const { ...rest } = props;
  //RegExp Validations
  const validEmailRegex = RegExp(
    /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
  );
  const validNumber = RegExp(/^[0-9]+$/);
  //Validate blank fields
  function isNotBlank(str) {
    return !(!str || /^\s*$/.test(str));
  }

  //Open Const Snackbars
  const [openError, setOpenError] = React.useState(false);
  const [openSuccess, setOpenSuccess] = React.useState(false);

  //Handles Snackbars
  const handleCloseError = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenError(false);
  };
  const handleCloseSuccess = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenSuccess(false);
  };

  //Verifify Data
  function verifyData(name, email, address, phone, description) {
    return (
      isNotBlank(name) &&
      isNotBlank(email) &&
      isNotBlank(address) &&
      isNotBlank(phone) &&
      isNotBlank(description) &&
      validEmailRegex.test(email) &&
      validNumber.test(phone)
    );
  }

  //Handle Submit Form
  const handleSubmit = useCallback(async (event) => {
    event.preventDefault();
    const { name, email, address, phone, description } = event.target.elements;
    const _name = name.value.trim();
    const _email = email.value.trim();
    const _address = address.value.trim();
    const _phone = phone.value.trim();
    const _description = description.value.trim();
    if (verifyData(_name, _email, _address, _phone, _description)) {
      //Send Email
      setOpenSuccess(true);
    } else {
      setOpenError(true);
    }
  });

  return (
    <div>
      <Header
        color="dark"
        routes={dashboardRoutes}
        rightLinks={<HeaderLinks />}
        fixed
        {...rest}
      />
      <div className={classNames(classes.main, classes.boxRaised)}>
        <br />
        <br />
        <div className={classes.container}>
          <GridContainer justify="center">
            <GridItem cs={12} sm={12} md={8}>
              <h2 className={classes.title}>Envía una cotización</h2>
              <h4 className={classes.description}>
                Divide details about your product or agency work into parts.
                Write a few lines about each one and contact us about any
                further collaboration. We will responde get back to you in a
                couple of hours.
              </h4>
              <form onSubmit={handleSubmit}>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={6}>
                    <CustomInput
                      orange
                      labelText="Nombre"
                      id="name"
                      formControlProps={{
                        fullWidth: true,
                      }}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6}>
                    <CustomInput
                      orange
                      labelText="Correo Electrónico"
                      id="email"
                      formControlProps={{
                        fullWidth: true,
                      }}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6}>
                    <CustomInput
                      orange
                      labelText="Dirección Obra"
                      id="address"
                      color={"danger"}
                      formControlProps={{
                        fullWidth: true,
                      }}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6}>
                    <CustomInput
                      orange
                      labelText="Teléfono"
                      id="phone"
                      warning
                      type="number"
                      formControlProps={{
                        fullWidth: true,
                      }}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={12}>
                    <CustomInput
                      orange
                      labelText="Descripción de la cotización"
                      id="description"
                      formControlProps={{
                        fullWidth: true,
                        className: classes.textArea,
                      }}
                      inputProps={{
                        multiline: true,
                        rows: 5,
                      }}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6}>
                    <Button color="warning" type={"submit"}>
                      Enviar Cotización
                    </Button>
                  </GridItem>
                </GridContainer>
              </form>
            </GridItem>
          </GridContainer>
        </div>
      </div>
      <Footer />
      <Snackbar
        open={openError}
        autoHideDuration={3000}
        onClose={handleCloseError}
      >
        <Alert onClose={handleCloseError} severity="error">
          Ha ocurrido un error al registrar la cotización, por favor verifica
          que todos los campos hayan sido diligenciados correctamente.
        </Alert>
      </Snackbar>
      <Snackbar
        open={openSuccess}
        autoHideDuration={3000}
        onClose={handleCloseSuccess}
      >
        <Alert onClose={handleCloseError} severity="success">
          Cotización registrada correctamente.
        </Alert>
      </Snackbar>
    </div>
  );
}
