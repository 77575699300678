import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons

// core components
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import HeaderLinks from "components/Header/HeaderLinks.js";

import styles from "assets/jss/material-kit-react/views/landingPage.js";
import BrandsSection from "./BrandsSection";
import { Helmet } from "react-helmet";
import { title } from "../../assets/jss/material-kit-react";

// Sections for this page
const dashboardRoutes = [];

const useStyles = makeStyles(styles);

export default function BrandsPage(props) {
  const classes = useStyles();
  const { ...rest } = props;
  const imageClasses = classNames(
    classes.imgRaised,
    classes.imgRoundedCircle,
    classes.imgFluid
  );
  return (
    <div>
      <Helmet>
        <title>Nuestras Marcas | El Jordán</title>
      </Helmet>
      <Header
        color="dark"
        routes={dashboardRoutes}
        rightLinks={<HeaderLinks />}
        fixed
        {...rest}
      />
      <div className={classNames(classes.main, classes.boxRaised)}>
        <div className={classes.container}>
          <BrandsSection/>
        </div>
      </div>
      <Footer />
    </div>
  );
}
