import React from "react";
import { isMobile } from "react-device-detect";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons

// core components
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Parallax from "components/Parallax/Parallax.js";

import styles from "assets/jss/material-kit-react/views/landingPage.js";

// Sections for this page
import ProductSection from "./Sections/ProductSection.js";
import { Helmet } from "react-helmet";
import { title } from "../../assets/jss/material-kit-react";
const dashboardRoutes = [];

const useStyles = makeStyles(styles);

export default function LandingPage(props) {
  const classes = useStyles();
  const { ...rest } = props;
  function getChat(){
    if(isMobile){
      return "whatsapp://send?phone=573202025864"
    } else {
      return "https://wa.me/573202025864"
    }
  }
  return (
    <div>
      <Helmet>
        <title>El Jordán - Depósito y Ferretería | Materiales Para Construcción</title>
      </Helmet>
      <Header
        color="transparent"
        routes={dashboardRoutes}
        brand="El Jordán"
        rightLinks={<HeaderLinks />}
        fixed
        changeColorOnScroll={{
          height: 200,
          color: "dark"
        }}
        {...rest}
      />
      <Parallax filter image={require("assets/img/lan-bg.jpg")}>
        <div className={classes.container}>
          <GridContainer>
            <GridItem xs={12} sm={12} md={6}>
              <h1 className={classes.title}>El Jordán <br/> Depósito y Ferretería</h1>
              <h4>
                Contamos con más de 30 años de experiencia en el sector de la construcción.
                Encuentra todo lo que necesitas para tu hogar y mucho más, estamos esperando
                para tener el gusto de atenderte.
              </h4>
              <br />
              <Button
                color="success"
                size="lg"
                href={getChat()}
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className="fab fa-whatsapp" />
                &nbsp;&nbsp;Escribenos
              </Button>
            </GridItem>
          </GridContainer>
        </div>
      </Parallax>
      <div className={classNames(classes.main, classes.mainRaised)}>
        <div className={classes.container}>
          <ProductSection />
        </div>
      </div>
      <Footer />
    </div>
  );
}
