import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";

import styles from "assets/jss/material-kit-react/views/landingPageSections/teamStyle.js";

import cemex from "assets/img/logos/cemex.jpg";
import diaco from "assets/img/logos/diaco.jpg";
import colmena from "assets/img/logos/colmena.jpg";
import gerfor from "assets/img/logos/gerfor.jpg";
import helios from "assets/img/logos/helios.jpg";
import toptec from "assets/img/logos/toptec.jpg";
import eternit from "assets/img/logos/eternit-min.jpg";
import santafe from "assets/img/logos/santafe-min.jpg";
import grival from "assets/img/logos/grival-min.jpg";
import pintuco from "assets/img/logos/pintuco-min.jpg";
import supermastick from "assets/img/logos/supermastick-min.jpg"
import sika from "assets/img/logos/sika-min.jpg"

const useStyles = makeStyles(styles);

export default function BrandsSection() {
  const classes = useStyles();
  const imageClasses = classNames(
    classes.imgRaised,
    classes.imgRoundedCircle,
    classes.imgFluid
  );
  return (
    <div className={classes.section}>
      <h2 className={classes.title}>Nuestras Marcas</h2>
      <div>
        <GridContainer className={classes.justifyCenter}>
          <GridItem xs={12} sm={12} md={8} >
            <h5 className={classes.description}>
              Encuentra las mejores marcas del mercado a precios increíbles,
              ten el respaldo en calidad construyendo con los líderes mundiales,
              descubre más visitándonos.
            </h5>
          </GridItem>
        </GridContainer>
        <br/>
        <GridContainer>
          <GridItem xs={12} sm={12} md={3}>
            <Card plain>
              <GridItem xs={12} sm={12} md={8} className={classes.itemGrid}>
                <img src={cemex} alt="..." className={imageClasses} />
              </GridItem>
              <h4 className={classes.cardTitle}>
                Cemex Colombia
                <br />
                <small className={classes.smallTitle}>Cemento</small>
              </h4>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={12} md={3}>
            <Card plain>
              <GridItem xs={12} sm={12} md={8} className={classes.itemGrid}>
                <img src={helios} alt="..." className={imageClasses} />
              </GridItem>
              <h4 className={classes.cardTitle}>
                Ladrillera Helios
                <br />
                <small className={classes.smallTitle}>Mampostería</small>
              </h4>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={12} md={3}>
            <Card plain>
              <GridItem xs={12} sm={12} md={8} className={classes.itemGrid}>
                <img src={diaco} alt="..." className={imageClasses} />
              </GridItem>
              <h4 className={classes.cardTitle}>
                Diaco
                <br />
                <small className={classes.smallTitle}>Acero</small>
              </h4>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={12} md={3}>
            <Card plain>
              <GridItem xs={12} sm={12} md={8} className={classes.itemGrid}>
                <img src={colmena} alt="..." className={imageClasses} />
              </GridItem>
              <h4 className={classes.cardTitle}>
                Colmena
                <br />
                <small className={classes.smallTitle}>Laminados</small>
              </h4>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={12} md={3}>
            <Card plain>
              <GridItem xs={12} sm={12} md={8} className={classes.itemGrid}>
                <img src={gerfor} alt="..." className={imageClasses} />
              </GridItem>
              <h4 className={classes.cardTitle}>
                Gerfor
                <br />
                <small className={classes.smallTitle}>Tuberías</small>
              </h4>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={12} md={3}>
            <Card plain>
              <GridItem xs={12} sm={12} md={8} className={classes.itemGrid}>
                <img src={toptec} alt="..." className={imageClasses} />
              </GridItem>
              <h4 className={classes.cardTitle}>
                TopTec
                <br />
                <small className={classes.smallTitle}>Cubiertas</small>
              </h4>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={12} md={3}>
            <Card plain>
              <GridItem xs={12} sm={12} md={8} className={classes.itemGrid}>
                <img src={grival} alt="..." className={imageClasses} />
              </GridItem>
              <h4 className={classes.cardTitle}>
                Grival
                <br />
                <small className={classes.smallTitle}>Griferías</small>
              </h4>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={12} md={3}>
            <Card plain>
              <GridItem xs={12} sm={12} md={8} className={classes.itemGrid}>
                <img src={eternit} alt="..." className={imageClasses} />
              </GridItem>
              <h4 className={classes.cardTitle}>
                Eternit
                <br />
                <small className={classes.smallTitle}>Cubiertas</small>
              </h4>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={12} md={3}>
            <Card plain>
              <GridItem xs={12} sm={12} md={8} className={classes.itemGrid}>
                <img src={santafe} alt="..." className={imageClasses} />
              </GridItem>
              <h4 className={classes.cardTitle}>
                Ladrillera Santafé
                <br />
                <small className={classes.smallTitle}>Mampostería</small>
              </h4>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={12} md={3}>
            <Card plain>
              <GridItem xs={12} sm={12} md={8} className={classes.itemGrid}>
                <img src={sika} alt="..." className={imageClasses} />
              </GridItem>
              <h4 className={classes.cardTitle}>
                Sika
                <br />
                <small className={classes.smallTitle}>Aditivos e Impermeabilizantes</small>
              </h4>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={12} md={3}>
            <Card plain>
              <GridItem xs={12} sm={12} md={8} className={classes.itemGrid}>
                <img src={pintuco} alt="..." className={imageClasses} />
              </GridItem>
              <h4 className={classes.cardTitle}>
                Pintuco
                <br />
                <small className={classes.smallTitle}>Pinturas</small>
              </h4>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={12} md={3}>
            <Card plain>
              <GridItem xs={12} sm={12} md={8} className={classes.itemGrid}>
                <img src={supermastick} alt="..." className={imageClasses} />
              </GridItem>
              <h4 className={classes.cardTitle}>
                Supermastick PR
                <br />
                <small className={classes.smallTitle}>Masillas</small>
              </h4>
            </Card>
          </GridItem>


        </GridContainer>
      </div>
    </div>
  );
}
