import React from "react";
import ReactDOM from "react-dom";
import { createBrowserHistory } from "history";
import { Router, Route, Switch } from "react-router-dom";
import ScrollToTop from "./functions/scrollTop";

import "assets/scss/material-kit-react.scss?v=1.9.0";

// pages for this product
import Components from "views/Components/Components.js";
import LandingPage from "views/LandingPage/LandingPage.js";
import BrandsPage from "views/BrandsPage/BrandsPage.js";
import DataPage from "views/AboutUsPages/DataPage/DataPage.js";
import CotizacionPage from "views/CotizacionPage/CotizacionPage";
import AboutPage from "views/AboutUsPages/AboutPage/AboutPage";
import SecurityPage from "views/ProductsPage/SecurityPage/SecurityPage";

var hist = createBrowserHistory();

ReactDOM.render(
  <Router history={hist}>
    <ScrollToTop />
    <Switch>
      <Route path="/components-mu" component={Components} />
      <Route path="/marcas" component={BrandsPage} />
      <Route path="/tratamiento-de-datos" component={DataPage}/>
      <Route path="/cotizacion" component={CotizacionPage}/>
      <Route path="/nosotros" component={AboutPage}/>
      <Route path="/fichas-seguridad" component={SecurityPage}/>
      <Route path="/" component={LandingPage} />
    </Switch>
  </Router>,
  document.getElementById("root")
);
