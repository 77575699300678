import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons

// core components
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import { cardTitle, container, title } from "../../../assets/jss/material-kit-react";
import imagesStyle from "../../../assets/jss/material-kit-react/imagesStyles";
import GridContainer from "../../../components/Grid/GridContainer";
import GridItem from "../../../components/Grid/GridItem";

//Images
import logojordan from "assets/img/photos/Logo-El-Jordan-min.jpg"
import cemex from "../../../assets/img/logos/cemex.jpg";
import Card from "../../../components/Card/Card";
import { blue } from "@material-ui/core/colors";
import { Helmet } from "react-helmet";

const styles = {
  container: {
    zIndex: "12",
    color: "#FFFFFF",
    ...container
  },
  subtitle: {
    fontSize: "1.313rem",
    maxWidth: "500px",
    margin: "10px auto 0"
  },
  main: {
    background: "#FFFFFF",
    position: "relative",
    zIndex: "3"
  },
  mainRaised: {
    margin: "-60px 30px 0px",
    borderRadius: "6px",
    boxShadow:
      "0 16px 24px 2px rgba(0, 0, 0, 0.14), 0 6px 30px 5px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2)"
  },
  boxRaised: {
    margin: "50px 30px 0px",
    borderRadius: "6px",
    boxShadow:
      "0 16px 24px 2px rgba(0, 0, 0, 0.14), 0 6px 30px 5px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2)"
  },
  section: {
    padding: "70px 0px 20px 0px",
    textAlign: "center"
  },
  title: {
    ...title,
    marginBottom: "1rem",
    marginTop: "30px",
    minHeight: "32px",
    textDecoration: "none"
  },
  titlealt: {
    color: "#3C4858",
    fontWeight: "700",
    fontFamily: `"Roboto Slab", "Times New Roman", serif`,
    minHeight: "32px",
    textDecoration: "none"
  },
  ...imagesStyle,
  itemGrid: {
    marginLeft: "auto",
    marginRight: "auto"
  },
  cardTitle,
  smallTitle: {
    color: "#6c757d"
  },
  description: {
    color: "#999",
    justifyContent: "left !important"
  },
  justifyCenter: {
    justifyContent: "center !important"
  },
  socials: {
    marginTop: "0",
    width: "100%",
    transform: "none",
    left: "0",
    top: "0",
    height: "100%",
    lineHeight: "41px",
    fontSize: "20px",
    color: "#999"
  },
  margin5: {
    margin: "5px"
  },
  pdfView: {
    maxHeight: "400px",
    maxWidth: "80%"
  },
  link: {
    color: "#FF8C00",
    textdecoration: "underline",
  }
};


// Sections for this page
const dashboardRoutes = [];
const useStyles = makeStyles(styles);

export default function SecurityPage(props) {
  const classes = useStyles();
  const { ...rest } = props;
  return (
    <div>
      <Helmet>
        <title>Fichas | El Jordán</title>
      </Helmet>
      <Header
        color="dark"
        routes={dashboardRoutes}
        rightLinks={<HeaderLinks />}
        fixed
        {...rest}
      />
      <div className={classNames(classes.main, classes.boxRaised)}>
        <div className={classes.container}>
          <div className={classes.section}>
            <h2 className={classes.title}>Fichas de Seguridad y Técnicas</h2>
          </div>
          <GridContainer className={classes.container}>
            <GridItem xs={12} sm={12} md={6}>
              <h3 className={classes.titlealt}>Cemex</h3>
              <a href={"https://www.cemexcolombia.com/documents/45752949/45757355/FDS-cemento-cemex-colombia.pdf/e9cc4c26-1dea-660f-49c3-2bd0214c327a"}
                 target={"_blank"}
                 className={classes.link}
              >Cementos (Superresistente y Blanco)</a>
              <br/>
              <h3 className={classes.titlealt}>Diaco</h3>
              <a href={"https://www.gerdaudiaco.com/wp-content/uploads/2019/12/A_FICHA-TECNICA-BARRAS-Y-ROLLOS-CORRUGADOS.pdf"}
                 target={"_blank"}
                 className={classes.link}
              >Barras Corrugadas</a>
              <h3 className={classes.titlealt}>Sika</h3>
              <a href={"https://col.sika.com/content/dam/dms/co01/j/Sika%C2%AE-1"}
                 target={"_blank"}
                 className={classes.link}
              >Sika 1</a>
              <br/>
              <a href={"https://col.sika.com/content/dam/dms/co01/6/Sikafill%C2%AE-7%20Power.pdf"}
                 target={"_blank"}
                 className={classes.link}
              >Sikafill®-7 Power</a>
              <br/>
              <a href={"https://col.sika.com/content/dam/dms/co01/d/co-hs_SikaMastic.pdf"}
                 target={"_blank"}
                 className={classes.link}
              >SikaMastic</a>
              <br/>
              <a href={"https://col.sika.com/content/dam/dms/co01/3/MSDS%20Sikaflex%C2%AE%20Universal.pdf"}
                 target={"_blank"}
                 className={classes.link}
              >Sikaflex® Universal</a>
              <br/>
              <a href={"https://col.sika.com/es/documentacion.html#"}
                 target={"_blank"}
                 className={classes.link}
              >Otros Productos Sika</a>
            </GridItem>
            <GridItem xs={12} sm={12} md={6}>
              <h3 className={classes.titlealt}>Gerfor</h3>
              <a href={"https://www.gerfor.com/wp-content/uploads/2020/07/Ficha-te%CC%81cnica-Soldaduras-gerfor.pdf"}
                 target={"_blank"}
                 className={classes.link}
              >Soldadura y Limpiador PVC</a>
              <br/>
              <h3 className={classes.titlealt}>Colmena</h3>
              <a href={"https://tuboscolmena.com/fichas%20tecnicas/Placa-Facil%20ficha%20tecnica%20tubos%20colmena.pdf"}
                 target={"_blank"}
                 className={classes.link}
              >Perfil Placa Fácil</a>
            </GridItem>
          </GridContainer>
          <br/>
          <br/>
        </div>
      </div>
      <Footer />
    </div>
  );
}
