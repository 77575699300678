import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// core components
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import { cardTitle, container, title } from "../../../assets/jss/material-kit-react";
import imagesStyle from "../../../assets/jss/material-kit-react/imagesStyles";
import GridContainer from "../../../components/Grid/GridContainer";
import GridItem from "../../../components/Grid/GridItem";
import { Helmet } from "react-helmet";

//Images
import logojordan from "assets/img/photos/Logo-El-Jordan-min.jpg"

const styles = {
  container: {
    zIndex: "12",
    color: "#FFFFFF",
    ...container
  },
  main: {
    background: "#FFFFFF",
    position: "relative",
    zIndex: "3"
  },
  boxRaised: {
    margin: "50px 30px 0px",
    borderRadius: "6px",
    boxShadow:
      "0 16px 24px 2px rgba(0, 0, 0, 0.14), 0 6px 30px 5px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2)"
  },
  section: {
    padding: "70px 0",
    textAlign: "center"
  },
  title: {
    ...title,
    marginBottom: "1rem",
    marginTop: "30px",
    minHeight: "32px",
    textDecoration: "none"
  },
  titlealt: {
    color: "#3C4858",
    fontWeight: "700",
    fontFamily: `"Roboto Slab", "Times New Roman", serif`,
    minHeight: "32px",
    textDecoration: "none"
  },
  ...imagesStyle,
  itemGrid: {
    marginLeft: "auto",
    marginRight: "auto"
  },
  cardTitle,
  smallTitle: {
    color: "#6c757d"
  },
  description: {
    color: "#999",
    justifyContent: "left !important"
  },
  justifyCenter: {
    justifyContent: "center !important"
  },
};


// Sections for this page
const dashboardRoutes = [];
const useStyles = makeStyles(styles);

export default function AboutPage(props) {
  const classes = useStyles();
  const { ...rest } = props;
  const imageClasses = classNames(
    classes.imgRaised,
    classes.imgFluid
  );
  return (
    <div>
      <Helmet>
        <title>Acerca de | El Jordán</title>
      </Helmet>
      <Header
        color="dark"
        routes={dashboardRoutes}
        rightLinks={<HeaderLinks />}
        fixed
        {...rest}
      />
      <div className={classNames(classes.main, classes.boxRaised)}>
        <div className={classes.container}>
          <div className={classes.section}>
            <h2 className={classes.title}>Nuestra Empresa</h2>
            <br/>
            <br/>
            <GridContainer>
              <GridItem xs={12} sm={12} md={4}>
                <img src={logojordan} alt="..." className={imageClasses} />
              </GridItem>
              <GridItem xs={12} sm={12} md={8}>
                <h3 className={classes.titlealt}>Historia</h3>
                <h5 className={classes.description} align={"justify"}>
                  Con el optimismo de tener una fuente de ingresos en el año 1987
                  se creó Depósito y Ferretería El Jordán siendo uno de los primeros
                  negocios de la naciente comunidad de Alfonso López en la localidad de Usme.
                  Al comienzo fue una empresa netamente familiar, pero con el crecimiento se
                  pudo generar empleo para la comunidad, actualmente con nueve empleados y varios
                  colaboradores externos. Durante este camino hemos adquirido gran experiencia
                  y conocimiento en el sector de la construcción por lo cuál nuestros clientes
                  nos reconocen como pioneros en la localidad.</h5>
              </GridItem>
              <GridItem xs={12} sm={12} md={6}>
                <h3 className={classes.titlealt}>Misión</h3>
                <h5 className={classes.description} align={"justify"}>
                  Depósito y Ferretería El Jordán a través de la comercialización
                  lleva a los consumidores finales productos con altos
                  estándares de calidad, apoyando su proceso de construcción para
                  que obtengan los mejores resultados, manteniendo como premisa
                  la buena atención al cliente donde se analizan necesidades y
                  se ofrecen soluciones personalizadas, además de cooperar
                  con socios comerciales en el sector para maximizar el impacto.</h5>
              </GridItem>
              <GridItem xs={12} sm={12} md={6}>
                <h3 className={classes.titlealt}>Visión</h3>
                <h5 className={classes.description} align={"justify"}>
                  Depósito y Ferretería El Jordán seguirá apoyando el crecimiento
                  del sector, para ello se planean nuevas alianzas comerciales
                  que permitan tener una mayor oferta de productos tanto para
                  clientes finales como para otros aliados comerciales que podrán
                  encontrar una eficiente línea de suministro.</h5>
              </GridItem>
            </GridContainer>

          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
